import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from "../components/layout"
import SEO from "../components/seo"
import TwitterIcon from "../images/twitter.png";
import LongDots from "../images/dots-extended.png";
import ResultsActual from "../images/results-actual.png";
import ResultsPerceived from "../images/results-perceived.png";
import ProfilePic from "../images/profile-pic.jpg";
import HeroPic from "../images/systems-over-goals.png";
import { 
  faChartLine,
  faGrinAlt,
  faWalking,
} from '@fortawesome/free-solid-svg-icons'

const HomePage = ({ data, location }) => {

  // console.log(data)
  // console.log(location)
  
  const date = new Date().toDateString();

  return (
    <Layout>
      <SEO title="Systems Over Goals | Systems vs Goals | Systems Deliver Goals" />
      <div>
        <div className="hero-wrapper">
          <div className="hero">
            <div className="container">
              <p><strong className="first-group">A Personal <span>System</span></strong> is a grouping of <strong>action items</strong> that you <strong>consistently</strong> do to <strong>improve your quality of life</strong> <em>as you see fit.</em></p>
              <img className="hero-pic" src={HeroPic} alt="Systems over goals" />
            </div>
          </div>
        </div>
        <div className="article">
          <div className="secondary-content">
            <div>
              <div className="features feature-list">
                <h2>Benefits of a<span> systems approach</span></h2>
                <ul>
                  <li>Specific Goals are Unnecessary</li>
                  <li>Actually, Deliver Results</li>
                  <li>Personally Fitted</li>
                  <li>Provide Quick Accurate Feedback</li>
                  <li>Extremely Versatile</li>
                </ul>
              </div>
            </div>
            <div className="featured-content">
              <p>Using a system over time is extremely powerful. The <em>compound effect</em> of your actions will lead to <strong>bigger breakthroughs</strong> and <strong>more opportunities</strong> for your future self. It can take weeks, months, or years. The longer you stick with it the <strong>larger your rewards will be.</strong></p>
              <h2 className="callout">Be impatient with actions, but patient with results.</h2>
              <p><strong>Action items</strong> are things you <strong>do</strong> or <strong>don't do</strong>. In most cases, they are new habits you're trying to create or old habits you’re trying to break.</p>
            </div>
          </div>
          <img className="section-break" src={LongDots} alt="" />
          <section className="getting-started article-section">
            <p className="hashtag">#SystemsOverGoals</p>
            <h2>Getting Started</h2>
            <div className="flex">
              <div className="example-content">
                <p>Make a list of action items on a piece of paper and track them every day.</p>
                <p>If your thinking this is just a to do. You are correct, it's your custom-fitted personal growth to do list. Tasks on your current to do list will become insignificant after a few weeks of using a system.</p>
                <p><strong>Showing up every day and building up momentum is the first step.</strong> Even if your first and only action item is Track this item daily. You've decided to show up and begin building momentum in a positive direction.</p>
                <p><strong>Tracking is critical</strong>, it gives you the feedback you need to keep moving forward or expose the holes in your system that you need to patch up.</p>
                <p>Working as a software engineer the past decade-plus, I've worked with, in, designed, and built my fair share of systems. Here is an example of a personal daily system.</p>
              </div>
              <div>
                <div className="feature-list">
                  <h3>Example Action Items</h3>
                  <ul>
                    <li>Walk at least 5 minutes</li>
                    <li>Eat at least one homemade meal</li>
                    <li>Read a book for 5 minutes</li>
                    <li>Say something nice to someone</li>
                    <li>No sweets other than fruit</li>
                    <li>Write at least one sentence</li>
                    <li>1 hour No Screen Time</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="example-table">
              <h3 className="handwriting">My Daily Improvement System</h3>
              <table>
                <thead>
                  <tr>
                    <th scope="row">Action Item</th>
                    <th scope="col">Su</th>
                    <th scope="col">M</th>
                    <th scope="col">Tu</th>
                    <th scope="col">W</th>
                    <th scope="col">Th</th>
                    <th scope="col">F</th>
                    <th scope="col">S</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Drink 2 Glasses of Water</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Show my Partner some love</td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>At least 5 minutes of learning Spanish</td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>5 Pushups</td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>$5 Cash in an envelope or $5 in Savings Account</td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Write at least 1 paragraph for a Blog Post</td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p><strong>It’s ok to start small</strong>, I know I did. Your system will change over time, just like you. New habits will be formed, old habits will be defeated. Remove those action items from your system. Or level them up to be slightly more difficult. Either way, you're winning.</p>
            <p>When my system initially failed, I briefly thought it was my fault. <strong>But quickly realized that I didn't fail, my system failed.</strong> I knew I could change the system to something that was a better fit for me. The action items were too ambitious for me at that point in my life.</p>
            <h2 className="callout">Failure is just an opportunity for improvement.</h2>
            <p>One of my first action items was <em>Exercise for at least 30 minutes</em>. Only completed this one a couple of times a week. <strong>Tracking gave me the feedback I needed</strong>, I wasn't ready for that yet. The action item became <em>Physical activity</em>. Going for a walk, strenuous yard work, exercising, dance parties with the kids are all forms of physical activity. I Consistently complete this item six or seven days a week.</p>
            <p>After a month I created another system. A weekly system with one action item, <em>20 minutes resistence training</em> with a target of two days per week.</p>
            <p><strong>Failing often early is a good thing.</strong> It allows you to build a solid foundation that is less likely to fail in the future. Every person starts from zero when learning something new.</p>
          </section>
          <img className="section-break" src={LongDots} alt="" />
          <section className="article-section">
            <p className="hashtag">#SystemsVsGoals</p>
            <h2>No Goals. No Problem.</h2>
            <p>Goals are not necessary for implementing a system. They do serve well as long-term targets. Specific or general goals both work extremely well with a systems approach.</p>
            <p>Most goals boil down to three things:</p>
            <ul className="goal-list">
              <li>
                <FontAwesomeIcon color="#fa7d09" icon={faGrinAlt} />
                <h3>Be Happier</h3>
              </li>
              <li>
                <FontAwesomeIcon color="#fa7d09" icon={faWalking} />
                <h3>Be Healthier</h3>
              </li>
              <li>
                <FontAwesomeIcon color="#fa7d09" icon={faChartLine} />
                <h3>Be Wealthier</h3>
              </li>
            </ul>
            <p>Creating action items that get you closer to any one or all of these, is the <strong>best recipe</strong> to put yourself in a position to <strong>help the people around you</strong>.</p>
            <p>If you've made it this far <strong>you are absolutely capable</strong> of achieving any of these goals and more.</p>
            <p>Systems are all about tweaking, adding, and removing action items based on <strong>feedback from tracking</strong> and <em>self-reflection</em>.</p>
            <p>A surprise will be the <strong>quality of</strong> and <strong>amount of</strong> goals you'll come up with using a system.</p> 
            <p>One of my favorite and most productive action items is <em>At least 10 minutes of brain activity</em>. Writing, reading, designing, coding, researching something new all fall under that item in my mind. Combine them all and you are currently viewing my creative outlet. Publishing this site was a specific goal I came up with, and completed using my system.</p>
            <p>In my short experience using a personal system, it has <strong>delivered goals</strong> in more ways than one.</p>
          </section>
          <img className="section-break" src={LongDots} alt="" />
          <section className="article-section">
            <p className="hashtag">#SystemsDeliverGoals</p>
            <h2>Call To Action</h2>
            <p><em>Take advantage of the versatility of systems.</em> Create a daily system or a weekly system, or both. Want to be a professional gamer, instagram influencer, ultra marathon runner, you name it. <strong>I guarantee you can make a system for it.</strong></p>
            <p>Be patient with results, but impatient with actions.</p>
            <div className="results-display">
              <div className="display left-display">
                <h4>How we <em>Think</em> progress happens</h4>
                <img src={ResultsPerceived} alt="Linear growth graph" />
              </div>
              <div className="display right-display">
                <h4>How progress <em>Actually</em> happens</h4>
                <img src={ResultsActual} alt="Exponential growth graph" />
              </div>
            </div>
            <h2 className="callout">You are only one or two habits away from success.</h2>
            <h2 className="callout action-callout">It's {date} what are you waiting for!</h2>
            <br />
            <p><strong>Grab your notebook, or get <a href="https://mypersonalsystem.com/">personal system tracker</a>. Give your system a name and create some action items. Start tracking them today, you’ll be glad you did tomorrow.</strong></p>
            <p>Thank you for your time. I hope you found this information valuable and actionable.</p>
          </section>
          <img className="section-break" src={LongDots} alt="" />
          <section className="more-info">
            <h2>More Information</h2>
            <p>The concept of <em>systems over goals</em> and <em>systems vs goals</em> was created by Scott Adams. It was later expanded upon by James Clear. Much of the information in this article I obtained while researching these concepts. This article is my interpretation of those concepts. My conclusion is that Systems Deliver Goals.</p>
            <p>During the Covid-19 pandemic of 2020, I decided to try implementing a systems approach to my life. The World was looking bleak and I wasn’t taking great care of myself. After a couple of weeks of mostly failures, I decided to read Atomic Habits by James Clear. An amazing book, with tons of actionable information to work with.</p>
            <p>I still needed to work out the personal system concept a little further. The result is what I am and will be publishing on this site.</p> 
            <p>Using a personal system helped me get my life back on a positive trajectory during one of the darkest times in recent history. <strong>I hope the information on this site can do the same for you.</strong></p>
            <div className="reference">
              <h3>References and Further Reading</h3>
              <h4><strong>Publishings</strong> by Scott Adams</h4>
              <p><a rel="noreferrer" href="https://www.goodreads.com/book/show/17859574-how-to-fail-at-almost-everything-and-still-win-big" target="_blank">How to Fail at Almost Everything and Still Win Big</a> (Goodreads Link) - Book - <em>2013</em></p>
              <p><a rel="noreferrer" href="https://www.scottadamssays.com/2013/11/18/goals-vs-systems/" target="_blank">Goals vs. Systems</a> - Article - <em>2013</em></p>
              <h4><strong>Publishings</strong> by James Clear</h4>
              <p><a rel="noreferrer" href="https://www.goodreads.com/book/show/40121378-atomic-habits" target="_blank">Atomic Habits</a> (Goodreads Link) - Book - <em>2018</em></p>
              <p><a rel="noreferrer" href="https://jamesclear.com/goals-systems" target="_blank">Forget About Setting Goals. Focus on This Instead.</a> - Article - <em>2018</em></p>
              <h4><strong>Publishings</strong> by Eric Jorgenson</h4>
              <p><a rel="noreferrer" href="https://www.navalmanack.com/" target="_blank">Almanack of Naval Ravikant. A Guide to Wealth and Happiness
  </a> - Book - <em>2020</em> - Free and Paid versions</p>
              <p><a rel="noreferrer" href="https://www.goodreads.com/book/show/54898389-the-almanack-of-naval-ravikant" target="_blank">Almanack of Naval Ravikant</a> (Goodreads Link) - Book - <em>2020</em></p>
            </div>
          </section>
          <section className="about-me">
          <h2>About Me</h2>
          <div className="flex">
            <div className="profile-pic">
              <img src={ProfilePic} width="100" alt="Nathan Olmstead" />
            </div>
            <div>
              <p>My name is Nathan Olmstead. I live in Southeast Michigan.</p>
              <p>I'm a Husband and Father of two young children. A formidable software engineer and mediocre designer. A retired Chef and hot sauce enthusiast. I enjoy wandering around in the woods.</p>
              <p className="connect">
                  Connect with me on Twitter 
                  <img src={TwitterIcon} alt="Twitter" width="20" /> 
                  <a rel="noreferrer" href="https://twitter.com/OlmsteadSystems" target="_blank"> @OlmsteadSystems</a>
                </p>
            </div>
          </div>
        </section>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
